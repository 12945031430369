import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Google Material Design Website Template | Uizard"
    category="Web App Templates"
    url="/templates/web-app-templates/google-material-design-web-app/"
    metaDescription="If you want to create a web app mockup embodying Material Design, working in Uizard might be a good choice for you! Customize online now."
    description="
    h2:Introducing PixLab: A Pinterest-style website mockup template using Google Material Design
    <br/>
    PixLab is a fully customizable photo web app template inspired by Pinterest. What's special about PixLab is that it reflects Material Design, the adaptable open-source design system created by Google. Created to support the best practices of user interface design, you can use PixLab as a <a:https://uizard.io/templates/web-app-templates/>web app template</a> for designing your own web application for photo mood boarding.
    <br/>
    h3:Developing a web application with Material Design
    <br/>
    If you want to create a web app mockup embodying Material Design, working in Uizard is the perfect choice for you. Uizard has the Material Design theme built-in, so you can use it when creating any mobile or web app mockup, <a:https://uizard.io/wireframing/>wireframe</a>, or prototype.
    <br/>
    h3:Endless web app creativity 
    <br/>
    Our photo web app template, PixLab, employs the tenets of Material Design giving your own design project a sound base in design best practices. Although PixLab is ready to use straight out of the box, Uizard's endless customization options mean you can really make your photo web app design your own.
    "
    pages={[
      "Two different views of images on the web app",
      "An individual page featuring a photo and its details",
    ]}
    projectCode="gWjgYQxdxOclZlnEr3LM"
    img1={data.image1.childImageSharp}
    img1alt="Screenshot of Google Material Design Web App: cover"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot of Google Material Design Web App: dashboard page"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot of Google Material Design Web App: photo thumbnails page"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot of Google Material Design Web App: image details page"
    img5={data.image5.childImageSharp}
    img5alt="Image summary of Google Material Design Web App template"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/google-material-design-web-app/GMDWA-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/google-material-design-web-app/GMDWA-dashboard.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/google-material-design-web-app/GMDWA-big-thumbnails.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/google-material-design-web-app/GMDWA-details.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/google-material-design-web-app/GMDWA-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
